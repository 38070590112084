import React, { useEffect } from "react";
import "./Design.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { assets } from "../../Assets/Assets";

function Design() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <div className="design">
      <div className="overlay"></div>
      <div className="content-box">
        <h2>It's Our Confidence</h2>
        <h4>Elevating Your Vision with Advanced Technologies</h4>
        <p>
          At Spine Codes, we pride ourselves on our deep expertise in advanced
          technologies. We are dedicated to delivering successful projects
          through innovation and excellence. We specialize in web and mobile app
          development, helping to bring your vision to life with the
          latest advancements.
        </p>
      </div>
      <ul>
        <li className="react">
          <img src={assets.reactimg} alt="" />
        </li>
        <li className="bootstrap">
          <img src={assets.boostra} alt="" />
        </li>
        <li className="figma">
          <img src={assets.figma} alt="" />
        </li>
        <li className="css">
          <img src={assets.css1} alt="" />
        </li>
        <li className="python">
          <img src={assets.python1} alt="" />
        </li>
        <li className="ai">
          <img src={assets.ai} alt="" />
        </li>
        <li className="flutter">
          <img src={assets.Flutter} alt="" />
        </li>
        <li className="js">
          <img src={assets.js} alt="" />
        </li>
        <li className="html">
          <img src={assets.html1} alt="" />
        </li>
        <li className="dart">
          <img src={assets.dart} alt="" />
        </li>
        <li className="Mongo">
          <img src={assets.mongo} alt="" />
        </li>
        <li className="firebase">
          <img src={assets.firebase} alt="" />
        </li>
      </ul>

      <div className="mobile_icon">
        <div className="mobile_icon_list">
          <div className="items">
            <img src={assets.reactimg} alt="" />
          </div>
          <div className="items">
            <img src={assets.boostra} alt="" />
          </div>
          <div className="items">
            <img src={assets.figma} alt="" />
          </div>
          <div className="items">
            <img src={assets.css1} alt="" />
          </div>
          <div className="items">
            <img src={assets.python1} alt="" />
          </div>
          <div className="items">
            <img src={assets.ai} alt="" />
          </div>
          <div className="items">
            <img src={assets.Flutter} alt="" />
          </div>
          <div className="items">
            <img src={assets.firebase} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Design;
